<template>
  <!--compatibility 男女合盘-->
  <div class="compatibility">
    <div class="nav">
      <h2>
        <span class="back" v-on:click="backToHistory()"
          >&lt;&nbsp;&nbsp;回历史</span
        >
      </h2>
    </div>
    <article class="compatibility-wrap">
      <!--cover 产品主视觉-->
      <section class="cover-preview cover">
        <div class="cover-txt">
          <h1>
            <span>真爱<em>太岁</em>合盘</span
            ><span>看看我和TA的速配程度？</span>
          </h1>
          <p>
            正挣扎着该不该表白吗？<br />桃花太多该选择谁？<br />忐忑不安的暧昧期，该行动还是放手？<br />桃桃喜来指点迷津！
          </p>
        </div>
      </section>
      <!--/cover 产品主视觉-->
      <!--report 合盘报告-->
      <article class="report">
        <!--user 使用者资料-->
        <section class="user">
          <section class="con-wrap user-wrap">
            <header class="user-hd">
              <div class="user-data">
                <p class="user-name">
                  您的资料：{{ result.name }} /
                  {{ result.gender == 1 ? '男' : '女' }}
                </p>
                <p class="user-subtit">
                  阳历<span class="num">{{
                    getMoment(result.birthdayStr).year()
                  }}</span
                  >年<span class="num">{{
                    getMoment(result.birthdayStr).month() + 1
                  }}</span
                  >月<span class="num">{{
                    getMoment(result.birthdayStr).date()
                  }}</span
                  >日<span class="num">{{
                    getSolarHour(getMoment(result.birthdayStr).hour())
                  }}</span>
                </p>
                <p class="user-subtit">
                  农历<span class="num">{{
                    getMoment(result.lunarBirthdayStr).year()
                  }}</span
                  >年<span class="num">{{
                    getMoment(result.lunarBirthdayStr).month() + 1
                  }}</span
                  >月<span class="num">{{
                    getMoment(result.lunarBirthdayStr).date()
                  }}</span
                  >日<span>{{
                    getLunarHour(getMoment(result.lunarBirthdayStr).hour())
                  }}</span
                  >时
                </p>
              </div>
              <hr />
              <div class="user-data">
                <p class="user-name">
                  对方资料：{{ result.targetName }} /
                  {{ result.targetGender == 1 ? '男' : '女' }}
                </p>
                <p class="user-subtit">
                  阳历<span class="num">{{
                    getMoment(result.targetBirthdayStr).year()
                  }}</span
                  >年<span class="num">{{
                    getMoment(result.targetBirthdayStr).month() + 1
                  }}</span
                  >月<span class="num">{{
                    getMoment(result.targetBirthdayStr).date()
                  }}</span
                  >日
                </p>
                <p class="user-subtit">
                  农历<span class="num">{{
                    getMoment(result.targetLunarBirthdayStr).year()
                  }}</span
                  >年<span class="num">{{
                    getMoment(result.targetLunarBirthdayStr).month() + 1
                  }}</span
                  >月<span class="num">{{
                    getMoment(result.targetLunarBirthdayStr).date()
                  }}</span
                  >日
                </p>
              </div>
            </header>
          </section>
        </section>
        <!--/user 使用者资料-->
        <section class="con-wrap report-wrap">
          <!--result 测算结果-->
          <section class="result">
            <h2 class="blk-tit">关于本测算结果</h2>
          </section>
          <!--/result 测算结果-->
          <!--you 关于你-->
          <section class="you">
            <h3 class="blk-tit"><span>你的感情观如何</span></h3>
            <section class="vision _you">
              <figure class="vision-cards">
                <div>
                  <img
                    v-for="(name, index) in result.q1.pic"
                    :key="index"
                    :src="'/image/star/' + name + '.jpeg'"
                  />
                </div>
                <figcaption class="cards-name">
                  <span>
                    {{ result.q1.xingZuo[0] }}
                  </span>
                </figcaption>
              </figure>
              <div class="vision-txt">
                <p v-for="(item, index) in result.q1.desc" :key="index">
                  {{ item }}
                </p>
              </div>
            </section>
            <hr />
            <h3 class="blk-tit">
              <span
                >你在对方眼中<br
                  class="lg-hide md-hide sm-hide xs-show"
                />是怎样的⼈</span
              >
            </h3>
            <section class="vision _other">
              <figure class="vision-cards">
                <div>
                  <img
                    v-for="(name, index) in result.q2.pic"
                    :key="index"
                    :src="'/image/star/' + name + '.jpeg'"
                  />
                </div>
                <figcaption class="cards-name">
                  <span>
                    {{ result.q2.xingZuo[0] }}
                  </span>
                </figcaption>
              </figure>
              <div class="vision-txt">
                <p v-for="(item, index) in result.q2.desc" :key="index">
                  {{ item }}
                </p>
              </div>
            </section>
          </section>
          <!--/you 关于你-->
          <!--other 关于对方-->
          <section class="other">
            <section class="other-about">
              <h3 class="blk-tit"><span>关于对方</span></h3>
              <h4>对方是怎样的⼈？</h4>
              <p v-for="(item, index) in result.q3.desc" :key="index">
                {{ item }}
              </p>
            </section>
            <hr />
            <!--other-beware 注意事项-->
            <section class="other-beware">
              <div class="beware-intro">
                <h3 class="blk-tit">
                  <span
                    >跟对方相处，<br
                      class="lg-hide md-hide sm-hide xs-show"
                    />你该知道的</span
                  >
                </h3>
              </div>
              <ol class="list-beware">
                <li class="score-add">
                  <div>
                    <header>
                      <h4>对方喜欢的事情<br />相处时积极加分的事</h4>
                    </header>
                    <p v-for="(item, index) in result.q4.desc" :key="index">
                      {{ item }}
                    </p>
                  </div>
                </li>
                <li class="score-deduct">
                  <div>
                    <header>
                      <h4>对方的地雷区<br />相处时应避免踩的雷</h4>
                    </header>
                    <p v-for="(item, index) in result.q5.desc" :key="index">
                      {{ item }}
                    </p>
                  </div>
                </li>
                <li class="together-add">
                  <div>
                    <header>
                      <h4>你们交往的优势</h4>
                    </header>
                    <p v-for="(item, index) in result.q6.desc" :key="index">
                      {{ item }}
                    </p>
                  </div>
                </li>
                <li class="together-deduct">
                  <div>
                    <header>
                      <h4>你们交往的劣势</h4>
                    </header>
                    <p v-for="(item, index) in result.q7.desc" :key="index">
                      {{ item }}
                    </p>
                  </div>
                </li>
              </ol>
            </section>
            <!--/other-beware 注意事项-->
          </section>
          <!--/other 关于对方-->
          <!--how 下一步如何-->
          <section class="how">
            <h3 class="blk-tit">
              <span
                >下⼀步<br
                  class="lg-hide md-hide sm-hide xs-show"
                />到底会如何？</span
              >
            </h3>
            <section class="how-summary">
              <figure class="summary-chart">
                <!-- <img src="/image/coupling/ill_chart_ability.png" /> -->
                <!-- <div id="fleetData" style="height: 250px;"></div> -->
                <v-chart
                  id="fleetData"
                  style="height: 250px;"
                  :options="option"
                />
              </figure>
              <div class="summary-txt">
                <h4>对方喜欢你吗？</h4>
                <p>
                  <span v-for="(item, index) in result.q8.desc" :key="index"
                    >{{ item }}<br
                  /></span>
                </p>
              </div>
            </section>
            <hr />
            <section class="how-chances">
              <div class="chances-percent">
                <!-- <div id="pie"></div> -->
                <v-chart id="pie" :options="option2" />
                <div class="percent-score">
                  <span
                    ><em>{{ result.q9.score * 100 }}</em
                    >分</span
                  >
                </div>
              </div>
              <div class="chances-txt">
                <h4>你们在⼀起的分数有多高？</h4>
                <p>
                  {{ result.q9.desc }}
                </p>
              </div>
            </section>
            <section class="how-years">
              <section class="years-best" style="background-image: none;">
                <h4><span>你们相处愉快的最佳年份</span></h4>
                <div>
                  <p v-if="result.q10.length !== 0">
                    <span v-for="(item, index) in result.q10" :key="index"
                      ><br v-if="index !== 0 && index % 2 === 0" /><span
                        v-else-if="index % 2 !== 0"
                        >、</span
                      >{{ item }}</span
                    >
                  </p>
                  <p v-else class="empty-years">
                    根据命盘来看，你们在最近10年都没有缘分引动强烈的最佳年份。这意味着你与对方要么相忘于江湖，无长久的感情羁绊；要么无须择时蓄力，需要更多后天努力去加强缘分联结。
                  </p>
                  <!-- <p>2022、2023<br />2024、2025</p> -->
                </div>
              </section>
              <section class="years-bad" style="background-image: none;">
                <h4><span>你们容易摩擦不顺的年份</span></h4>
                <div>
                  <p v-if="result.q11.length !== 0">
                    <span v-for="(item, index) in result.q11" :key="index"
                      ><br v-if="index !== 0 && index % 2 === 0" /><span
                        v-else-if="index % 2 !== 0"
                        >、</span
                      >{{ item }}</span
                    >
                  </p>
                  <p v-else class="empty-years">
                    恭喜！根据命盘来看，如果你们二人结合，在未来10年中，并无严重不顺年份。这意味着当发生冲突时，你们多会抱着包容缓和之态度，多能平安度过！即便分开，也是和平分手，不会弄得鸡飞狗跳！
                  </p>
                </div>
              </section>
            </section>
          </section>
          <!--/how 下一步如何-->
        </section>
      </article>
      <!--/report 合盘报告-->
    </article>
  </div>
  <!--/compatibility 男女合盘-->
</template>

<script>
import store from '@/store'
import moment from 'moment-timezone'

import 'echarts/lib/chart/radar'
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/tooltip'

import { mapGetters } from 'vuex'
import { SHI_CHEN } from '@/constants/shi-chen-list'

export default {
  name: 'Result',
  data() {
    return {
      result: null,
      ready: false
    }
  },
  beforeRouteEnter(to, from, next) {
    if (
      'undefined' === typeof store.getters['fortune/search'](to.params.orderNo)
    ) {
      var payload = {
        ttxid: store.state.user.ttxid,
        token: store.state.user.token,
        timestamp: store.state.user.timestamp,
        orderNo: to.params.orderNo
      }
      store.dispatch('fortune/getResult', payload).then(
        () => {
          next()
        },
        err => {
          alert('尚未支付')
          next({ name: 'Home' })
        }
      )
    } else {
      next()
    }
  },
  created: function() {
    this.result = { ...this.search(this.$route.params.orderNo) }
    try {
      TtxTrack.track('he-pan', 'FORTUNE_RESULT')
    } catch (e) {
      // console.log(e)
    }
  },
  methods: {
    backToHistory: function() {
      this.$router.push({ name: 'History' })
    },
    getMoment: function(timeString) {
      return moment.tz(timeString, 'YYYYMMDDHH', 'Etc/GMT-8')
    },
    getSolarHour: function(hour) {
      let str
      if (0 === hour || 23 === hour) {
        str = hour + ':00 ~ ' + hour + ':59'
      } else {
        str = hour + ':00 ~ ' + (hour + 1) + ':59'
      }
      return str
    },
    getLunarHour: function(hour) {
      if (hour !== 0 && hour % 2 === 0) {
        hour--
      }
      return SHI_CHEN[hour]
    }
  },
  computed: {
    ...mapGetters('fortune', {
      search: 'search',
      isLoading: 'isLoading'
    }),
    option: function() {
      return {
        radar: {
          name: {
            textStyle: {
              color: '#333',
              borderRadius: 3,
              padding: [-10, -15],
              fontSize: 12
            }
          },
          center: ['50%', '50%'],
          indicator: [
            { name: '交往优势', max: 5 },
            { name: '不吵架指数', max: 5 },
            { name: '在一起机率', max: 5 },
            { name: '喜欢程度', max: 5 },
            { name: '交往稳定度', max: 5 }
          ],
          radius: '60%',
          axisLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.8)'
            }
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: 'rgba(0, 0, 0, 0.6)'
            }
          },
          splitArea: {
            areaStyle: {
              color: [
                'rgba(255, 255, 255, 0.2)',
                'rgba(255, 255, 255, 0.2)',
                'rgba(255, 255, 255, 0.2)',
                'rgba(255, 255, 255, 0.2)',
                'rgba(255, 255, 255, 0.2)'
              ]
              // shadowColor: 'rgba(0, 0, 0, 0.3)',
              // shadowBlur: 10
            }
          }
        },
        series: [
          {
            type: 'radar',
            data: [
              {
                value: [
                  this.result.starScore.advScore,
                  this.result.starScore.stableScore,
                  this.result.starScore.possibilityScore,
                  this.result.starScore.likeScore,
                  this.result.starScore.disadvScore
                ],
                symbol: 'none',
                lineStyle: {
                  color: 'rgba(11, 150, 145, 1)'
                },
                areaStyle: {
                  color: 'rgba(11, 150, 145, 0.7)'
                }
              }
            ]
          }
        ]
      }
    },
    option2: function() {
      return {
        series: [
          {
            type: 'pie',
            radius: '100%',
            center: ['50%', '50%'],
            data: [
              {
                value: this.result.q9.score,
                itemStyle: {
                  color: '#dc7962'
                }
              },
              {
                value: 1 - this.result.q9.score,
                itemStyle: {
                  color: '#aaa'
                }
              }
            ].sort(function(a, b) {
              return b.value - a.value
            }),
            label: {
              show: false
            },

            silent: true
          }
        ]
      }
    }
  }
}
</script>

<style src="@/assets/css/compatibility.css"></style>
<style>
.echarts {
  width: 100%;
  height: 100%;
}
.nav {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.back {
  position: absolute;
  left: 3%;
}
</style>
